<template>
    <div class="page flex-col">
        <div class="group_1 flex-row">
            <div class="section_1 flex-col">
                <div class="group_2 flex-col"></div>
                <div class="group_3 flex-row">
                    <div class="section_2 flex-col justify-between">
                        <div class="block_1 flex-col"></div>
                        <span class="text_1">
                            Somos&nbsp;una&nbsp;empresa&nbsp;de&nbsp;préstamos&nbsp;en&nbsp;línea,&nbsp;fundada&nbsp;con&nbsp;la&nbsp;misión&nbsp;de&nbsp;brindar&nbsp;soluciones&nbsp;financieras&nbsp;rápidas&nbsp;y&nbsp;accesibles.&nbsp;Desde&nbsp;el&nbsp;principio,&nbsp;nos&nbsp;enfocamos&nbsp;en&nbsp;usar&nbsp;la&nbsp;tecnología&nbsp;para&nbsp;simplificar&nbsp;el&nbsp;proceso&nbsp;de&nbsp;préstamo,&nbsp;permitiendo&nbsp;a&nbsp;nuestros&nbsp;clientes&nbsp;obtener&nbsp;fondos&nbsp;de&nbsp;manera&nbsp;segura&nbsp;y&nbsp;conveniente.&nbsp;A&nbsp;lo&nbsp;largo&nbsp;de&nbsp;los&nbsp;años,&nbsp;hemos&nbsp;ganado&nbsp;la&nbsp;confianza&nbsp;de&nbsp;miles&nbsp;de&nbsp;usuarios&nbsp;gracias&nbsp;a&nbsp;nuestra&nbsp;transparencia&nbsp;y&nbsp;excelente&nbsp;servicio&nbsp;al&nbsp;cliente.
                        </span>
                    </div>
                    <img
                        class="image_1"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng658410ceb66d7665a0aebecebb8afc956c1b380177654a41ecf0cbd7ef78bb0f.png"
                    />
                </div>
                <div class="group_4 flex-row">
                    <div class="group_5 flex-col"></div>
                    <div class="text-wrapper_1">
                        <span class="text_2">-&nbsp;</span>
                        <span class="text_3"
                            >Límite&nbsp;del&nbsp;préstamo:&nbsp;</span
                        >
                        <span class="paragraph_1">
                            De&nbsp;2,000&nbsp;hasta&nbsp;80,000&nbsp;pesos
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_4"
                            >Plazo&nbsp;del&nbsp;préstamo:&nbsp;</span
                        >
                        <span class="paragraph_2">
                            De&nbsp;120&nbsp;a&nbsp;365&nbsp;días
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_5">IVA:</span>
                        <span class="paragraph_3">
                            &nbsp;&nbsp;16%
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_6">Comisión:</span>
                        <span class="paragraph_4">
                            &nbsp;&nbsp;0%
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_7">Tasa&nbsp;de&nbsp;interés:</span>
                        <span class="paragraph_5">
                            &nbsp;&nbsp;Variable,&nbsp;hasta&nbsp;2.3%&nbsp;mensual&nbsp;(32%&nbsp;anual&nbsp;máxima)
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_8">Ejemplo&nbsp;de&nbsp;Cálculo</span>
                        <span class="paragraph_6">
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_9"
                            >Monto&nbsp;del&nbsp;préstamo:&nbsp;</span
                        >
                        <span class="paragraph_7">
                            $5,000&nbsp;pesos
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_10"
                            >Plazo&nbsp;del&nbsp;préstamo:</span
                        >
                        <span class="paragraph_8">
                            &nbsp;240&nbsp;días
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_11"
                            >Cálculo&nbsp;del&nbsp;interés:</span
                        >
                        <span class="paragraph_9">
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_12">Interés&nbsp;mensual:</span>
                        <span class="paragraph_10">
                            &nbsp;&nbsp;$5,000&nbsp;*&nbsp;2.3%&nbsp;=&nbsp;$115
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_13"
                            >Interés&nbsp;total&nbsp;por&nbsp;8&nbsp;meses:</span
                        >
                        <span class="paragraph_11">
                            &nbsp;&nbsp;$115&nbsp;*&nbsp;8&nbsp;=&nbsp;$920
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_14"
                            >IVA&nbsp;sobre&nbsp;el&nbsp;interés:</span
                        >
                        <span class="paragraph_12">
                            &nbsp;$920&nbsp;*&nbsp;16%&nbsp;=&nbsp;$147.20
                            <br />
                            -&nbsp;
                        </span>
                        <span class="text_15">Total&nbsp;a&nbsp;pagar:</span>
                        <span class="text_16"
                            >&nbsp;&nbsp;$5,000&nbsp;+&nbsp;$920&nbsp;+&nbsp;$147.20&nbsp;=&nbsp;$6,067.20</span
                        >
                    </div>
                    <div class="group_6 flex-col"></div>
                    <div class="group_7 flex-col"></div>
                    <img
                        class="image_2"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng9efc70cb93c6331b1f7025fe2f6498cac51fb20d9a8ad8fab1faf57448fb6634.png"
                    />
                    <span class="text_17"
                        >Descripción&nbsp;del&nbsp;Producto</span
                    >
                </div>
                <div class="group_8 flex-row justify-between">
                    <div class="group_9 flex-col">
                        <div class="group_10 flex-col"></div>
                        <div class="group_11 flex-row">
                            <div class="image-text_1 flex-row justify-between">
                                <div class="box_1 flex-col"></div>
                                <span class="text-group_1">
                                    Rápido&nbsp;y&nbsp;confiable,&nbsp;obtuve&nbsp;el&nbsp;préstamo&nbsp;que&nbsp;necesitaba&nbsp;en&nbsp;minutos.
                                </span>
                            </div>
                        </div>
                        <div class="group_12 flex-col"></div>
                    </div>
                    <div class="group_13 flex-col">
                        <div class="group_14 flex-row">
                            <div class="image-text_2 flex-row justify-between">
                                <div class="section_3 flex-col"></div>
                                <span class="text-group_2">
                                    Transparencia&nbsp;y&nbsp;rapidez,&nbsp;los&nbsp;recomiendo&nbsp;al&nbsp;100%.
                                </span>
                            </div>
                        </div>
                        <div class="group_15 flex-col"></div>
                        <div class="group_16 flex-row">
                            <div class="image-text_3 flex-row justify-between">
                                <div class="group_17 flex-col"></div>
                                <span class="text-group_3">
                                    La&nbsp;mejor&nbsp;opción&nbsp;para&nbsp;préstamos&nbsp;en&nbsp;línea,&nbsp;fácil&nbsp;y&nbsp;seguro.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group_18 flex-col">
                    <img
                        class="image_3"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPngbfd042dcf39cacf39f57875a3d9e1b283dcdf16c9175a568a0ed1ccddbf8c367.png"
                    />
                    <div class="block_2 flex-row">
                        <div class="group_19 flex-row">
                            <div class="image-text_4 flex-row justify-between">
                                <div
                                    class="text-group_4 flex-col justify-between"
                                >
                                    <div class="text-wrapper_2">
                                        <span class="text_18">1</span>
                                        <span class="text_19">.</span>
                                    </div>
                                    <span class="text_20">
                                        Descarga&nbsp;la&nbsp;app&nbsp;y&nbsp;regístrate&nbsp;para&nbsp;tener&nbsp;una&nbsp;cuenta&nbsp;propia.
                                    </span>
                                </div>
                                <img
                                    class="image_4"
                                    referrerpolicy="no-referrer"
                                    src="./assets/img/SketchPng4a1bc9ae095a61d4a566214d692a9efba30cd699b3a62c1b7d4ec1b95fd33d5a.png"
                                />
                            </div>
                            <span class="text_21"
                                >Descargar&nbsp;la&nbsp;app</span
                            >
                        </div>
                        <div class="group_20 flex-row">
                            <div class="text-wrapper_3">
                                <span class="text_22">2</span>
                                <span class="text_23">.</span>
                            </div>
                            <span class="text_24"
                                >Completa&nbsp;la&nbsp;información</span
                            >
                            <img
                                class="image_5"
                                referrerpolicy="no-referrer"
                                src="./assets/img/SketchPngec19e3fca673a0508612a302085e2440a6722710ad2010eb4edb83dadc9809c0.png"
                            />
                            <span class="text_25">
                                Proporciona&nbsp;información&nbsp;básica&nbsp;para&nbsp;recibir&nbsp;un&nbsp;servicio&nbsp;personalizado.
                            </span>
                        </div>
                        <div class="group_21 flex-row">
                            <div class="group_22 flex-col justify-between">
                                <div class="block_3 flex-row justify-between">
                                    <div class="text-wrapper_4">
                                        <span class="text_26">3</span>
                                        <span class="text_27">.</span>
                                    </div>
                                    <span class="text_28">Depositar</span>
                                </div>
                                <span class="text_29">
                                    ¡Espera&nbsp;la&nbsp;transferencia&nbsp;de&nbsp;fondos,&nbsp;es&nbsp;súper&nbsp;rápida!
                                </span>
                            </div>
                            <img
                                class="image_6"
                                referrerpolicy="no-referrer"
                                src="./assets/img/SketchPng152270b9f072ea0c73168d236537368180a0c62a7e52d682f3d24f43381eb147.png"
                            />
                        </div>
                    </div>
                </div>
                <img
                    class="image_7"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng5bae696ba08f2a444dca813bdb258b9a918fbfedd35cd1c0b073111f715595fe.png"
                />
                <div class="group_23 flex-col">
                    <div class="block_4 flex-row justify-between">
                        <div class="section_4 flex-col justify-end">
                            <div class="box_2 flex-col"></div>
                            <div class="image-text_5 flex-row justify-between">
                                <img
                                    class="label_1"
                                    referrerpolicy="no-referrer"
                                    src="./assets/img/SketchPngfd37707802edf22dc89830463745f3dc5259bf930d0cfc2828f1a0cb10b5e962.png"
                                />
                                <span class="text-group_5">
                                    Empresa：Quantum&nbsp;Lion,&nbsp;S.A.P.I.&nbsp;de&nbsp;C.V.,&nbsp;SOFOM,&nbsp;E.N.R.
                                </span>
                            </div>
                            <div class="image-text_6 flex-row justify-between">
                                <img
                                    class="label_2"
                                    referrerpolicy="no-referrer"
                                    src="./assets/img/SketchPng36433a6b563e314cb870167b9b961b50712c26b5989350dfd3d79111788946af.png"
                                />
                                <span class="text-group_6"
                                    >Teléfono：5584682138</span
                                >
                            </div>
                            <div class="image-text_7 flex-row justify-between">
                                <img
                                    class="label_3"
                                    referrerpolicy="no-referrer"
                                    src="./assets/img/SketchPngc28ee87ac55783a587d4fb48b63afedcfd3fc788285702963e9ad2962b69cdd9.png"
                                />
                                <span class="text-group_7"
                                    >Correo：servicio&#64;quantumlion.mx</span
                                >
                            </div>
                            <a class="text_30" id="section">Contáctanos</a>
                        </div>
                        <div class="section_5 flex-col">
                            <div class="block_5 flex-col"></div>
                        </div>
                    </div>
                    <div class="image-text_8 flex-row justify-between">
                        <img
                            class="label_4"
                            referrerpolicy="no-referrer"
                            src="./assets/img/SketchPng9c31b5cada20ede9e4599a0c08271f5ef6fe77e13ce102d15bcb5d34a8334c2c.png"
                        />
                        <span class="text-group_8">
                            Domicilio:&nbsp;Torres&nbsp;Adalid&nbsp;No.&nbsp;618&nbsp;Del&nbsp;Valle&nbsp;Centro,&nbsp;Benito&nbsp;juárez&nbsp;méxico,&nbsp;CIUDAD&nbsp;DE&nbsp;méxico,&nbsp;03100&nbsp;méxico
                        </span>
                    </div>
                </div>
                <div class="group_24 flex-col"></div>
                <div class="group_25 flex-col">
                    <div class="box_3 flex-col">
                        <div class="section_6 flex-col"></div>
                    </div>
                </div>
                <span class="text_31"
                    >Quantum&nbsp;Lion,&nbsp;S.A.P.I.&nbsp;de&nbsp;C.V.,&nbsp;SOFOM,&nbsp;E.N.R.</span
                >
                <span class="text_32">Opiniones&nbsp;de&nbsp;clientes</span>
                <div class="group_26 flex-row">
                    <div class="image-text_9 flex-row justify-between">
                        <div class="box_4 flex-col"></div>
                        <span class="text-group_9">
                            Proceso&nbsp;sencillo&nbsp;y&nbsp;sin&nbsp;complicaciones,&nbsp;totalmente&nbsp;recomendable.
                        </span>
                    </div>
                </div>
                <div class="group_27 flex-row">
                    <div class="image-text_10 flex-row justify-between">
                        <div class="section_7 flex-col"></div>
                        <span class="text-group_10">
                            Excelente&nbsp;servicio&nbsp;al&nbsp;cliente,&nbsp;siempre&nbsp;están&nbsp;disponibles&nbsp;para&nbsp;ayudar.
                        </span>
                    </div>
                </div>
                <div class="group_28 flex-col"></div>
                <div class="group_29 flex-col">
                    <div class="group_30 flex-col"></div>
                </div>
                <img
                    class="image_8"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng341578c65c3b167f47c677f17cfe6e5e4322afe15c417715b7710d22aa312034.png"
                />
                <div class="group_31 flex-col"></div>
            </div>
            <div class="section_8 flex-row">
                <div class="image-text_11 flex-row justify-between">
                    <div class="section_9 flex-col">
                        <div class="block_6 flex-col">
                            <div class="box_5 flex-col">
                                <div class="block_7 flex-col"></div>
                                <div class="text-wrapper_5 flex-col">
                                    <span class="text_33">$</span>
                                </div>
                            </div>
                        </div>
                        <img
                            class="image_9"
                            referrerpolicy="no-referrer"
                            src="./assets/img/SketchPng00a9bb566a6a5640b88379b9ef17f51a0240bf27a6fa2357c366da5a45701173.png"
                        />
                        <div class="block_8 flex-col justify-center">
                            <div class="image-wrapper_1 flex-col">
                                <img
                                    class="thumbnail_1"
                                    referrerpolicy="no-referrer"
                                    src="./assets/img/SketchPngac7546aaa97af44ddcddcc4242738ecf71015fef0f14543c4eef7a47c76af2d9.png"
                                />
                                <img
                                    class="thumbnail_2"
                                    referrerpolicy="no-referrer"
                                    src="./assets/img/SketchPng80c196a5dbfb993bf22ce38d28b0132272d7ac37e97d7b3d7c5c2632692fdcf5.png"
                                />
                            </div>
                        </div>
                        <div class="block_9 flex-col">
                            <div class="block_10 flex-col"></div>
                        </div>
                        <div class="text-wrapper_6 flex-col">
                            <span class="text_34">LoanMXN</span>
                        </div>
                    </div>
                    <a
                        class="text-group_11"
                        @click.prevent="tabJump('políticadeprivacidad')"
                        style="cursor: pointer"
                        >《Politica&nbsp;de&nbsp;privacidad》</a
                    >
                </div>
                <img
                    class="image_10"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng91c3f620a0cd3607c89520da73ab9eefd23af87090aef8c53fd51373e7ed7c05.png"
                />
                <div class="text-wrapper_7">
                    <span class="text_35">Tel:</span>
                    <span class="text_36">+525584682138&nbsp;｜</span>
                    <span class="text_37">&nbsp;Correo:</span>
                    <span class="paragraph_13">
                        &nbsp;servicio&#64;quantumlion.mx
                        <br />
                    </span>
                    <span class="text_38"
                        >Domicilio:&nbsp;Torres&nbsp;Adalid&nbsp;No.&nbsp;618&nbsp;Del&nbsp;Valle&nbsp;Centro,&nbsp;Benito&nbsp;juárez&nbsp;méxico,&nbsp;CIUDAD&nbsp;DE&nbsp;méxico,&nbsp;03100&nbsp;méxico
                    </span>
                </div>
            </div>
        </div>
        <div class="group_32 flex-col">
            <div class="group_33 flex-row justify-between">
                <div class="group_34 flex-col"></div>
                <div class="group_35 flex-col"></div>
            </div>
            <div class="group_36 flex-row justify-between">
                <img
                    class="image_11"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPngeb1d4ba18da8822bed6ab50e689966b4529d6e93eedd54d86f4b1281262881b6.png"
                />
                <div class="image-wrapper_2 flex-col justify-between">
                    <img
                        class="image_12"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng14694225ced3d83118f5961e546849ce0d47814bebe4162d35b43652dffcf818.png"
                    />
                    <img
                        class="image_13"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPngfc2b1fecf7ec1247a6ef6b6611bf25e6db8d5115301aad819e8f661b308f2278.png"
                    />
                </div>
            </div>
            <div class="group_37 flex-row">
                <div class="text-wrapper_8">
                    <span class="text_40">Fácil&nbsp;de&nbsp;operar</span>
                    <span class="text_41">/</span>
                    <span class="text_42">&nbsp;Límite&nbsp;alto</span>
                    <span class="text_43">/</span>
                    <span class="text_44">&nbsp;Pago&nbsp;rápido</span>
                </div>
            </div>
            <img
                class="image_14"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng93947d379c2947d1f7ac7bd07eca085b32fa3a24be702bf40718c477c7bfa056.png"
            />
            <div class="group_38 flex-col">
                <div class="box_6 flex-col">
                    <div class="box_7 flex-col">
                        <div class="block_11 flex-col">
                            <div class="image-wrapper_3 flex-col">
                                <img
                                    class="image_15"
                                    referrerpolicy="no-referrer"
                                    src="./assets/img/12011903f0f546bcb8194a9390dca9c7_mergeImage.png"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="group_39 flex-row">
                <div class="box_8 flex-col justify-between">
                    <div class="text-wrapper_9 flex-row justify-between">
                        <span class="text_45">MX$</span>
                        <span class="text_46">$20,000</span>
                        <span class="text_47">Máximo</span>
                    </div>
                    <div class="text-wrapper_10">
                        <span class="text_48"
                            >Interés&nbsp;mínimo&nbsp;de&nbsp;</span
                        >
                        <span class="text_49"></span>
                        <span class="text_50">0.5%&nbsp;</span>
                        <span class="text_51"></span>
                        <span class="text_52"
                            >/día,&nbsp;plazo&nbsp;máximo&nbsp;de&nbsp;</span
                        >
                        <span class="text_53"></span>
                        <span class="text_54">365&nbsp;</span>
                        <span class="text_55"></span>
                        <span class="text_56">días</span>
                    </div>
                </div>
                <div class="text-wrapper_11 flex-col">
                    <span class="text_57">Descargar&nbsp;la&nbsp;app</span>
                </div>
            </div>
            <div class="group_40 flex-row">
                <div class="group_41 flex-col">
                    <div class="block_12 flex-col">
                        <div class="section_10 flex-col">
                            <div class="group_42 flex-col"></div>
                            <div class="text-wrapper_12 flex-col">
                                <span class="text_58">$</span>
                            </div>
                        </div>
                    </div>
                    <img
                        class="image_16"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPngaea889466a20e50f5c7b14030e53c4c9f5c51c5bdd54361b10d3a315245fd1cd.png"
                    />
                    <div class="block_13 flex-col justify-center">
                        <div class="image-wrapper_4 flex-col">
                            <img
                                class="thumbnail_3"
                                referrerpolicy="no-referrer"
                                src="./assets/img/SketchPngb9e8a17fa057c37aec7b7b6b7706a89af3af2a3c760345da13a2d688bb2c6a11.png"
                            />
                        </div>
                    </div>
                    <div class="block_14 flex-col">
                        <div class="block_15 flex-col">
                            <div class="box_9 flex-col"></div>
                        </div>
                    </div>
                    <div class="text-wrapper_13 flex-col">
                        <span class="text_59">LoanMXN</span>
                    </div>
                </div>
                <span class="text_60">Quantum&nbsp;Lion-LoanMXN</span>
                <div class="group_43 flex-col justify-between">
                    <span
                        class="text_61"
                        style="cursor: pointer"
                        @click="tabJump('sobrenosotros')"
                        >Sobre&nbsp;Nosotros</span
                    >
                    <div class="group_44 flex-col"></div>
                </div>
                <a
                    class="text_62"
                    style="cursor: pointer"
                    @click="tabJump('contáctanos')"
                    >Contáctanos</a
                >
                <span
                    class="text_63"
                    style="cursor: pointer"
                    @click="tabJump('políticadeprivacidad')"
                    >Política&nbsp;de&nbsp;Privacidad</span
                >
                <span
                    class="text_64"
                    style="cursor: pointer"
                    @click="tabJump('eliminarcuenta')"
                    >Eliminar&nbsp;Cuenta</span
                >
            </div>
            <span class="paragraph_14">
                Quantum&nbsp;
                <br />
                Lion-LoanMXN
            </span>
            <div class="group_45 flex-col"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            constants: {}
        }
    },
    watch: {
        // 监听路由对象中的`$route`属性，它包含了当前的路由信息
        $route: {
            handler: function (to, from) {
                // 路由发生变化时会触发这个函数
                // `to` 和 `from` 是两个路由对象，分别代表将要进入的路由和当前导航正要离开的路由
                console.log('Route changed from', from, 'to', to)
                if (to.path == '/contáctanos') {
                    this.$nextTick(() => {
                        // 滚动到指定位置
                        this.scrollToBottom()
                    })
                    // document.querySelector(".section_4").scrollIntoView();
                }
            },
            // 如果需要在路由变化时立即响应，可以设置immediate为true
            immediate: true,
            deep: true
        }
    },
    methods: {
        scrollToBottom() {
            document.querySelector('.section_4').scrollIntoView()
        },
        tabJump(value) {
            if (this.$route.path == '/' + value) {
                if ('/' + value == '/contáctanos') {
                    // 滚动到指定位置
                    this.scrollToBottom()
                }
                return
            }

            this.$router.push('/' + value)
        }
    },
    mounted() {}
}
</script>
<style scoped lang="css" src="./assets/index.response.css" />