import Vue from 'vue'
import VueRouter from 'vue-router'
import políticadeprivacidad from '../views/políticadeprivacidad/index.vue'
import sobrenosotros from "../views/sobrenosotros/index.vue"
import sobrenosotrosyipingxuanfu from "../views/sobrenosotrosyipingxuanfu/index.vue"
import eliminarcuenta from "../views/eliminarcuenta/index.vue"
import eliminarcuentatishi from "../views/eliminarcuentatishi/index.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/sobrenosotros"
  },
  {
    path: '/contáctanos',
    name: "/contáctanos",
    component: sobrenosotros
  },
  {
    path: '/sobrenosotros',
    name: 'sobrenosotros',
    component: sobrenosotros
  },
  {
    path: '/sobrenosotrosyipingxuanfu',
    name: 'sobrenosotrosyipingxuanfu',
    component: sobrenosotrosyipingxuanfu
  },
  {
    path: '/políticadeprivacidad',
    name: 'políticadeprivacidad',
    component: políticadeprivacidad
  },
  {
    path: '/eliminarcuenta',
    name: 'eliminarcuenta',
    component: eliminarcuenta
  }, {
    path: '/eliminarcuentatishi',
    name: 'eliminarcuentatishi',
    component: eliminarcuentatishi
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {  
//   console.log("to:",to);
  
//   if (to.path == '/contáctanos' && from.path == '/contáctanos') {  
//     // 这里不直接处理滚动，因为DOM可能还没渲染  
//     next(vm => {  
//       // vm.$el 现在是可用的，但更好的是等待mounted或nextTick  
//       vm.$nextTick(() => {  
//         // 滚动到指定位置
//         document.querySelector(".section_4").scrollIntoView();
//         return
//       });  
//     });  
//   } else {  
//     next();  
//   }  
// });  
 

export default router
